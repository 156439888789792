


.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    flex-grow: 1;

}


.bottom ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap:wrap;
}

.bottom ul li {
    display: flex ;
    align-items: center;
    justify-content: left;

}
.rating {
    margin-left: 15px;
}


.top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
}


.container1 {
    position: relative;
    text-align: center;
    color: white;
}

/* Bottom left text */
.topleft {
    position: absolute;
    top: 8px;
    left: 10px;
    padding: 3px;
background: white;
    color: black;
    font-weight: 700;
}

.none{
    display: none!important;
}

.notnone{
    display: block;
}
.containerSceny{
    display: flex;
    flex-direction: column;
    /*border: #2B96CC solid 2px;*/
    background: #e4d804;
    width: 64px;
    height: 45px;
    color: black;
    border-radius: 20%;
    box-shadow: 2px 1px 1px black;
}

.qualitySceny {
//border: 2px solid #fff;
    padding: 2px 2px;
    line-height: 1;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    color: black;
    text-align: center;

}

.priseSceny {
    color: black;
    background: white;
    font-size: 14px;
    margin: 2px;
    text-align: center;
    text-transform: lowercase;
    box-shadow: 2px 1px 1px gray;
    border-radius: 20%;
}

