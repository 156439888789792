




.title{
    margin-top: 10px;
    font-size: 16px;
}

.moviecontent{
    max-width: 170px;
}




.none{
    display: none!important;
}

.movieimg{


}

.imgposter{

    max-width: 170px;
    height: 250px;

}

