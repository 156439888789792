

.red{
    color: red;
font-size: 20px;
}

.green{
    color: #34c70a;
    font-size: 20px;
}

.border{
    border: 5px solid blue;
}

.now{
    border: 3px solid green;
}