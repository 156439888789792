.error{
    border: 1px red solid!important;
   /*// color: red!important;*/
}

.message{
    font-size: 16px!important;
    display: block;
}

.notmessage{
    font-size: 18px!important;
    display: none;
}