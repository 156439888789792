
.blockFilms > p {

    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #313035;
}
.blockFilms > p span {

    font-weight: 600;
    color: #fafafa;
}



.blockFilms {

    background: #1f1e24;
    padding: 20px 24px 20px;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.46);
}

.blockFilm{
    font-size: 18px;
    /*display: flex;*/
   /*flex-direction: row;*/
   /* flex-wrap: wrap;*/

}

.filmsTime{
    margin: 5px;
    color: #fafafa;
}

.nameFilm{
    margin: 5px;
    color: #e4d804;
}
.film23D{
    margin: 5px;
    font-style: italic;
    color: #cdcaca;
    border: 1px solid #38373c;
}
.filmPlace{
    margin: 5px;
    font-style: italic;
    color: #cdcaca;
}


.filmPrise{
    margin: 5px;
    font-style: italic;
    color: #cdcaca;
}
.yearLimit{
    margin: 5px;
    font-style: italic;
    color: #cdcaca;
    border: 1px solid #38373c;
}


.filmsCategory{


}



.filmTimeLimit{


}

.filmCountry{

}

@media (max-width: 560px) {
    .blockFilm{
        font-size: 16px;
    }
    .filmPlace{
       display: none;
    }
    .film23D{
        display: none;
    }
    .yearLimit{
        display: none;
    }
    .filmPriseText{
        display: none;
    }
    .blockFilms{
        padding: 10px 2px 15px 2px;
    }




}


.sdvoenny{
    display: flex;
flex-direction: row;

    /*border: 2px solid red;*/
}
.sdvoenny2{
    display: flex;


    /*border: 2px solid red;*/
}
.bg {
    background-position: top center;
    background-size: cover;
    padding: 120px 0;
}